import config from '@/src/config';

export const fundraisingDashboardResponsive: Array<LinkTemplate> = [
  'fundraiserDashboard',
  'fundraiserRecurring',
];

export type LinkTemplate = keyof typeof linkTemplates;

export const linkTemplates = {
  // -- Misc --
  home: '%lg3BaseUrl%/',
  profile: '%lg3BaseUrl%/user/newprofile#!/user-profile/profile/%username%',
  startCampaign: '%lg3BaseUrl%/start',
  howItWorks: '%lg3BaseUrl%/how-it-works',
  discover: '%lg3BaseUrl%/discover',
  legacy: '%lg3BaseUrl%/legacy',
  emergency: '%lg3BaseUrl%/emergency',
  emergencyRelief:
    '%lg3BaseUrl%/discover#!?f%5B%5D=category:emergency-relief&f%5B%5D=hide_community_campaigns:true',
  zakatLandingPage: '%lg3BaseUrl%/zakat',
  fridayGivers: '%lg3BaseUrl%/club#!/giving_club/friday-givers?src=footer',
  aboutUs: '%lg3BaseUrl%/about-us',
  careers: '%lg3BaseUrl%/careers',
  blog: '%lg3BaseUrl%/blog',
  safety: '%lg3BaseUrl%/safety',
  copyright: '%lg3BaseUrl%/copyright',
  termsOfUse: '%lg3BaseUrl%/terms-of-use',
  privacy: '%lg3BaseUrl%/privacy',
  createCampaign: '%lg3BaseUrl%/start',
  startCommunity: '%lg3BaseUrl%/communities/start',
  // -- My giving --
  donations: '%lg3BaseUrl%/user/newprofile#!/user-profile/donations/%username%',
  scheduledGiving:
    '%lg3BaseUrl%/user/newprofile#!/user-profile/auto-giving-and-challenges/%username%',
  zakat: '%lg3BaseUrl%/user/newprofile#!/user-profile/zakat/%username%',
  givingList:
    '%lg3BaseUrl%/user/newprofile#!/user-profile/give-list/%username%',
  tax: '%baseUrl%/user/taxes',
  fridayGiversDonor:
    '%lg3BaseUrl%/user/newprofile#!/user-profile/auto-giving-and-challenges/%username%/club/friday-givers/join',
  ramadanReferrals: '%baseUrl%/ramadan?src=lg_sitewide_admin_panel_referrals',
  eid: '%baseUrl%/eid',

  dhcReferrals: '%baseUrl%/dhc?src=lg_sitewide_admin_panel_referrals',
  loginPage: '%lg3BaseUrl%/loginpage#!/?url=%username%',

  // -- My fundraising --
  campaigns:
    '%lg3BaseUrl%/user/newprofile#!/user-profile/campaigns/%username%/active-campaigns',
  communities:
    '%lg3BaseUrl%/user/newprofile#!/user-profile/communities/%username%',
  reports: '%baseUrl%/fundraising/financial',

  // -- My settings --
  editSettings:
    '%lg3BaseUrl%/user/newprofile#!/user-profile/edit-settings/%username%',
  editProfile:
    '%lg3BaseUrl%/user/newprofile#!/user-profile/edit-profile/%username%',
  paymentMethods:
    '%lg3BaseUrl%/user/newprofile#!/user-profile/cards-and-payment-methods/%username%',

  // -- Support --
  supportHome: '%supportUrl%/home',
  supportResources: '%supportUrl%/support/solutions/35000140783',
  supportContact: '%supportUrl%/support/tickets/new',
  supportPayoutLeanMore:
    '%supportUrl%/support/solutions/articles/35000013641-how-can-i-receive-a-payout-',
  supportRamadanChallenge: '%supportUrl%/support/solutions/folders/35000225679',
  supportDHC:
    '%supportUrl%/support/solutions/articles/35000220930-dhul-hijjah-challenge-terms-and-conditions',

  supportRamadanLeaderboardLearnMore:
    '%supportUrl%/support/solutions/articles/35000154464-all-about-the-ramadan-challenge-for-campaign-creators-#Daily-Leaderboard-Contest%C2%A0',

  // -- Blog --
  blogHome: '%blogs%',
  blogDhulHijjah:
    '%blogs%/posts/fundraise-during-the-first-10-days-of-dhul-hijjah',
  blogDhulHijjahImpact:
    '%blogs%/posts/qurbani-fundraising-2-0---elevate-your-impact-this-dhul-hijjah',
  blogRamadan: '%blogs%/posts/unveiling-the-power-of-ramadan-fundraising',
  // -- Admin --
  admin: '%lg3BaseUrl%/newadmin#!/admin/dashboard',

  // -- fundaaiser dashboard --
  fundraiserDashboard: '%baseUrl%/fundraising/dashboard',
  fundraiserRecurring: '%baseUrl%/fundraising/recurring',
  fundraiserDashboardLeaderboards: '%baseUrl%/fundraising/leaderboards',
  fundraiserDashboardDhulHijjah: '%baseUrl%/fundraising/dhul-hijjah',
  fundraiserDashboardDhulHijjahReferrals:
    '%baseUrl%/fundraising/dhul-hijjah/referrals',
  fundraiserDashboardDhulHijjahLeaderboards:
    '%baseUrl%/fundraising/dhul-hijjah/leaderboards',
  fundraiserDashboardRamadan: '%baseUrl%/fundraising/ramadan',
  fundraiserDashboardQurbani: '%baseUrl%/fundraising/qurbani',
  fundraiserDashboardReferrals: '%baseUrl%/fundraising/referrals',
  ramadanLeaderboardsGlobal: '%baseUrl%/leaderboards/global',
  fundraiserDashboardCampaigns: '%baseUrl%/fundraising/fundraisers',
  fundraiserDashboardCommunities: '%baseUrl%/fundraising/communities',
  fundraiserDashboardDonations: '%baseUrl%/fundraising/donations',
  fundraiserDashboardReports: '%baseUrl%/fundraising/reports',
  fundraiserDashboardPayouts: '%baseUrl%/fundraising/payouts',
  fundraiserReportDhulHijjah1445:
    '%baseUrl%/fundraising/reports/dhul-hijjah-1445/referrals',
  fundraiserReportRamadan1445: '%baseUrl%/fundraising/reports/ramadan-1445',
  fundraiserReportQurbani1445: '%baseUrl%/fundraising/reports/qurbani-1445',
  fundraiserDashboardFundraisersLive: '%baseUrl%/fundraising/fundraisers?tab=2',
};

export function buildLink(linkName: LinkTemplate, username = '') {
  const template = linkTemplates[linkName];
  return (
    template
      // https://beta.launchgood.com
      .replace('%baseUrl%', config().baseUrl)
      // https://www.launchgood.com
      .replace('%lg3BaseUrl%', config().lg3BaseUrl)
      // https://support.launchgood.com
      .replace('%supportUrl%', config().supportUrl)
      // https://blog.launchgood.com
      .replace('%blogs%', config().blogUrl)
      // matthew.martin4
      .replace('%username%', username)
  );
}

export const lg3DonationLink = (campaignId: number, givingLevel?: number) => {
  return `${config().lg3BaseUrl}/project/donate/${campaignId}/${givingLevel}`;
};

export const campaignDhcReferralLink = (campaignSlug: string) => {
  return `${config().lg3BaseUrl}${campaignSlug}/dhc/referrals`;
};

export const v4AssetUrl = (assetUrl: string) => {
  return `${config().baseUrl}${assetUrl}`;
};

export const getRecurringDonationPage = (campaignSlug: string) => {
  return `${
    config().baseUrl
  }/scheduled-giving/charities/${campaignSlug}/checkout`;
};
